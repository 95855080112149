<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-bottom mb-1 mb-lg-5">
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <h3 class="wizard-title"><span>1</span> Базовые настройки</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>2</span> Время проведения</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>3</span> Создание теста</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">
                  <span>4</span> Завершение и проверка
                </h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center py-12 px-8 py-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-7">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h4 class="mb-10 font-weight-bold text-dark">
                  Общие настройки теста
                </h4>
                <!-- <div class="form-group">
                  <b-form-group label="Заключение">
                    <b-form-radio-group
                      label="Как оформляется заключение"
                      v-slot="{ ariaDescribedby }"
                      v-model="process.processType"
                      buttons
                    >
                      <b-form-radio
                        value="safe"
                        button-variant="outline-success"
                        :aria-describedby="ariaDescribedby"
                      >
                        Автоматически
                      </b-form-radio>
                      <b-form-radio
                        value="unsafe"
                        button-variant="outline-danger"
                        :aria-describedby="ariaDescribedby"
                      >
                        Специалистом
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <span class="form-text text-muted"
                    >В случае выбора режима работы специалиста результат
                    тестирования будет направлен специалисту с соответствующей
                    ролью</span
                  >
                </div> -->
                <div class="form-group">
                  <label>Название</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    v-model="process.processName"
                    placeholder="Название теста"
                  />
                  <span class="form-text text-muted">Укажите название.</span>
                </div>
                <div class="form-group">
                  <label>Описание</label>
                  <textarea
                    class="form-control form-control-solid form-control-lg"
                    v-model="process.processDescription"
                    placeholder="Описание теста или инструкция"
                  ></textarea>
                  <span class="form-text text-muted"
                    >Введите описание для заполняющих.</span
                  >
                </div>
                <div class="form-group">
                  <label>Тип теста</label>
                  <select v-model="processAimSelected" class="form-control">
                    <option disabled value="" data-description="">
                      Выберите
                    </option>
                    <option
                      v-for="item in processAims"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <span
                    class="form-text text-muted"
                    v-if="processAimSelected === ''"
                    >Выберите тип теста.</span
                  >
                  <span class="form-text text-primary" v-else>{{
                    findDescription()
                  }}</span>
                </div>
                <div class="form-group">
                  <b-form-group label="Выводить вопросы сразу">
                    <b-form-radio-group
                      label="Тип теста"
                      v-slot="{ ariaDescribedby }"
                      v-model="process.visibleType"
                      buttons
                    >
                      <b-form-radio
                        :value="true"
                        button-variant="outline-primary"
                        :aria-describedby="ariaDescribedby"
                      >
                        Да, все сразу
                      </b-form-radio>
                      <b-form-radio
                        :value="false"
                        button-variant="outline-info"
                        :aria-describedby="ariaDescribedby"
                      >
                        Нет, по одному
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <span class="form-text text-muted"
                    >Влияет на отображение при заполнении теста</span
                  >
                </div>
                <div class="form-group" v-if="!process.visibleType">
                  <b-form-group label="Выводить вопросы в случайном порядке">
                    <b-form-radio-group
                      label="Рандомизировать"
                      v-slot="{ ariaDescribedby }"
                      v-model="process.randomize"
                      buttons
                    >
                      <b-form-radio
                        :value="true"
                        button-variant="outline-primary"
                        :aria-describedby="ariaDescribedby"
                      >
                        Да, выводить в случайном порядке
                      </b-form-radio>
                      <b-form-radio
                        :value="false"
                        button-variant="outline-info"
                        :aria-describedby="ariaDescribedby"
                      >
                        Нет, в указанном
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <span class="form-text text-muted"
                    >Выводить случайным образом или нет</span
                  >
                </div>

                <!-- PAYMENT SETTINGS -->
                <div class="form-group">
                  <b-form-group label="Оплачиваемый тест">
                    <b-form-radio-group
                      label="Оплачиваемый тест"
                      v-slot="{ ariaDescribedby }"
                      v-model="process.payment"
                      buttons
                    >
                      <b-form-radio
                        :value="true"
                        button-variant="outline-primary"
                        :aria-describedby="ariaDescribedby"
                      >
                        Да
                      </b-form-radio>
                      <b-form-radio
                        :value="false"
                        button-variant="outline-info"
                        :aria-describedby="ariaDescribedby"
                      >
                        Нет
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <span class="form-text text-muted"
                    >Позволяет выводить модуль оплаты при заполнении теста</span
                  >
                </div>
                <div class="form-group" v-if="process.payment">
                  <label>Сумма</label>
                  <input
                    type="number"
                    min="1"
                    class="form-control form-control-solid form-control-lg"
                    v-model="process.price"
                    placeholder="Название теста"
                  />
                  <span class="form-text text-muted"
                    >Сумма к оплате за тест.</span
                  >
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Временные критерии теста
                </h4>
                <div class="form-group">
                  <label>Временные критерии для теста</label>
                  <select
                    v-model.number="process.processTime"
                    class="form-control"
                  >
                    <option disabled value="0">Выберите</option>
                    <option value="1">Постоянно действующий</option>
                    <option value="2">Разовый</option>
                    <option value="3">Повторяющийся</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="date-start">Дата начала</label>
                  <b-form-datepicker
                    id="date-start"
                    v-model="process.processDateStart"
                    locale="ru"
                    :min="today"
                    :start-weekday="1"
                    label-help="Используйте стрелки на клавиатуре для выбора дат"
                    placeholder="Выберите дату"
                  ></b-form-datepicker>
                  <span class="form-text text-muted"
                    >Укажите дату начала теста.</span
                  >
                </div>
                <div
                  class="form-group"
                  v-show="
                    process.processTime === 2 || process.processTime === 3
                  "
                >
                  <label for="date-end">Дата окончания</label>
                  <b-form-datepicker
                    id="date-end"
                    v-model="process.processDateFinish"
                    locale="ru"
                    :min="today"
                    :start-weekday="1"
                    label-help="Используйте стрелки на клавиатуре для выбора дат"
                    placeholder="Выберите дату"
                  ></b-form-datepicker>
                  <span class="form-text text-muted"
                    >Укажите дату окончания теста.</span
                  >
                </div>
                <div class="form-group" v-show="process.processTime === 3">
                  <label>Периодичность выполнения теста</label>
                  <select class="form-control" v-model="process.processPeriod">
                    <option value="0" disabled>Выберите</option>
                    <option value="daily">Ежедневно</option>
                    <option value="weekly">Еженедельно</option>
                    <option value="monthly">Каждый месяц</option>
                    <option value="yearly">Каждый год</option>
                  </select>
                  <span class="form-text text-muted"
                    >Выберите периодичность теста.</span
                  >
                </div>

                <div class="form-group">
                  <b-form-group label="Часы действия теста">
                    <b-form-checkbox v-model="process.hours" switch
                      >Круглосуточно</b-form-checkbox
                    >
                  </b-form-group>

                  <span class="form-text text-muted"
                    >Выберите часы действия теста - он будет доступен для
                    заполнения только в указанный интервал времени.</span
                  >
                </div>
                <div class="form-group" v-show="!process.hours">
                  <div class="input-group timepicker">
                    <input
                      class="form-control timepickerinput"
                      readonly=""
                      placeholder="С"
                      type="time"
                      v-model="process.timeStart"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i class="la la-clock-o"></i>
                      </span>
                    </div>
                  </div>
                  <div class="input-group timepicker mt-2">
                    <input
                      class="form-control timepickerinput"
                      readonly=""
                      placeholder="До"
                      type="time"
                      v-model="process.timeFinish"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i class="la la-clock-o"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Формирование структуры теста
                </h4>
                <!--<div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <b-form-group
                        label="Выбор объектов"
                        v-slot="{ ariaDescribedby }"
                      >
                        <b-form-checkbox
                          v-model="selectAllLocations"
                          value="all"
                          size="lg"
                          switch
                          >Выбрать все</b-form-checkbox
                        >
                        <b-form-checkbox-group
                          id="select-locations"
                          v-model="process.selectedLocations"
                          :aria-describedby="ariaDescribedby"
                          name="select-locations"
                        >
                          <b-form-checkbox
                            v-for="location in locations"
                            :key="location.id"
                            :value="location.id"
                            size="lg"
                          >
                            {{ location.LocationName }}
                          </b-form-checkbox>
                        </b-form-checkbox-group>
                      </b-form-group>
                      <span class="form-text text-muted"
                        >Выберите объекты, для которых будет доступен тест</span
                      >
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <b-form-group
                        label="Выбор ролей"
                        v-slot="{ ariaDescribedby }"
                      >
                        <b-form-checkbox
                          v-model="selectAllRoles"
                          value="all"
                          size="lg"
                          switch
                          >Выбрать все</b-form-checkbox
                        >
                        <b-form-checkbox-group
                          id="select-roles"
                          v-model="process.selectedRoles"
                          :aria-describedby="ariaDescribedby"
                          name="select-roles"
                        >
                          <b-form-checkbox
                            v-for="role in roles"
                            :key="role.id"
                            :value="role.id"
                            size="lg"
                          >
                            {{ role.name }}
                          </b-form-checkbox>
                        </b-form-checkbox-group>
                      </b-form-group>
                      <span class="form-text text-muted"
                        >Выберите роли, для которых будет доступна
                        инспекция</span
                      >
                    </div>
                  </div>
                </div>-->

                <!--begin: CheckList -->
                <div class="form-group">
                  <label>Добавление структуры теста</label>
                  <span class="form-text text-muted"
                    >Задайте структуру теста, используя разделы и вопросы внутри
                    разделов. Для вопросов также можно указать уточняющие
                    вопросы путем выбора опции "Вытекающий вопрос" справа от
                    поля ввода ответа в выпадающем списке. <br />Раздел с
                    основной информацией является общим для всех тестов и не
                    может быть изменен или удален. Для тестов с заключением
                    специалиста может быть назначен конкретный специалист.</span
                  >
                </div>

                <div
                  class="card bg-light-warning mt-3"
                  v-for="section in checklistStructure.sections"
                  :key="section.order"
                >
                  <div class="card-header bg-light-warning">
                    <div class="form-group row">
                      <label class="col-12">Название раздела</label>
                      <div class="col-10">
                        <input
                          type="text"
                          class="form-control"
                          v-model="section.name"
                        />
                      </div>
                      <div class="col-2">
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown"
                          no-caret
                          right
                          no-flip
                          text="Действия"
                          v-b-tooltip.hover="'Действия'"
                        >
                          <template v-slot:button-content>
                            <a
                              href="#"
                              class="btn btn-icon"
                              data-toggle="dropdown"
                            >
                              <span
                                class="svg-icon svg-icon-success svg-icon-2x"
                              >
                                <!--begin::Svg Icon-->
                                <inline-svg
                                  src="media/svg/icons/Code/Plus.svg"
                                />
                                <!--end::Svg Icon-->
                              </span>
                            </a>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text
                              tag="div"
                              class="navi-header font-weight-bold"
                            >
                              <i
                                class="flaticon2-information"
                                data-toggle="tooltip"
                                data-placement="left"
                                v-b-tooltip.hover
                                title="Дублирование всего раздела со всей структурой"
                              />
                              Для этого раздела доступны действия:
                            </b-dropdown-text>
                            <b-dropdown-text
                              tag="div"
                              class="navi-separator mb-3"
                            ></b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a
                                href="#"
                                class="navi-link"
                                @click.prevent="
                                  copyPasteSection(
                                    section,
                                    checklistStructure.sections.length + 1
                                  )
                                "
                              >
                                <span class="navi-icon">
                                  <i class="flaticon-add-circular-button"></i>
                                </span>
                                <span class="navi-text"
                                  >Дублировать раздел</span
                                >
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="checklistStructure.sections.length > 1"
                            >
                              <a
                                href="#"
                                class="navi-link"
                                @click.prevent="deleteSection(section)"
                              >
                                <span class="navi-icon">
                                  <i class="flaticon-delete-1"></i>
                                </span>
                                <span class="navi-text">Удалить</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <Question
                      v-for="question in section.questions"
                      :key="question.order"
                      :parent="section"
                      :question="question"
                      :bg="0"
                    ></Question>
                    <div class="form-group form-group-last row mt-3">
                      <div class="col-12">
                        <button
                          type="button"
                          class="btn btn-sm btn-primary"
                          @click.prevent="
                            addQuestion(section, section.questions.length + 1)
                          "
                        >
                          <i class="la la-plus"></i> Добавить вопрос
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-group form-group-last row mt-3 justify-content-center"
                >
                  <button
                    type="button"
                    class="btn btn-outline-success btn-lg"
                    @click.prevent="
                      addSection(checklistStructure.sections.length + 1)
                    "
                  >
                    <i class="flaticon-plus"></i> Добавить раздел
                  </button>
                </div>

                <!--end: CheckList -->
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Просмотр и подтверждение
                </h4>
                <div class="border-bottom mb-5 pb-5">
                  <div class="font-weight-bold mb-3">
                    Кому направить уведомления:
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12">
                        <b-form-group
                          label="Выбор групп рассылок"
                          v-slot="{ ariaDescribedby }"
                        >
                          <b-form-checkbox
                            v-model="selectAllMailgroups"
                            value="all"
                            size="lg"
                            switch
                            >Выбрать все</b-form-checkbox
                          >
                          <b-form-checkbox-group
                            id="select-mailgroups"
                            v-model="process.selectedMailgroups"
                            :aria-describedby="ariaDescribedby"
                            name="select-mailgroups"
                          >
                            <b-form-checkbox
                              v-for="mailgroup in mailgroups"
                              :key="mailgroup.id"
                              :value="mailgroup.id"
                              size="lg"
                            >
                              {{ mailgroup.key }}
                            </b-form-checkbox>
                          </b-form-checkbox-group>
                        </b-form-group>
                        <span class="form-text text-muted"
                          >Выберите группы рассылок, кого уведомить о новом
                          тесте</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-5">
                  <div class="font-weight-bold mb-3">Предпросмотр</div>
                  <div class="line-height-md">
                    <Inspection
                      :inspection="checklistPreview"
                      :readonly="true"
                      :safe="process.processType"
                    ></Inspection>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 4-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    Назад
                  </button>
                </div>
                <div>
                  <button
                    v-if="getProcessStatus !== 'preview'"
                    v-on:click="submit"
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Подтвердить
                  </button>
                  <button
                    v-else
                    v-on:click="gotoAll"
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Вернуться
                  </button>
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                  >
                    Далее
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  SET_CHECKLIST_STRUCTURE,
  SET_PROCESS,
  SEND_PROCESS,
  PURGE_PROCESS_STATE
} from "@/core/services/store/process.module";
import ApiService from "@/core/services/api.service";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";

import Question from "./components/Question";
import Inspection from "@/view/content/components/Inspection";

export default {
  name: "process-new",
  components: {
    Question,
    Inspection
  },
  data() {
    var d = new Date();
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var today = [year, month, day].join("-");
    return {
      processAims: [],
      locations: [],
      roles: [],
      mailgroups: [],
      today: today
    };
  },

  computed: {
    ...mapGetters(["layoutConfig", "getProcessStatus"]),

    config() {
      return this.layoutConfig();
    },

    processAimSelected: {
      get: function() {
        return this.process.processAim.id;
      },
      set: function(id) {
        this.process.processAim = this.processAims.find(el => el.id === id);
      }
    },

    checklistStructure: {
      get: function() {
        return this.$store.getters.currentCheckList;
      },
      set: function(checklist) {
        this.$store.commit(SET_CHECKLIST_STRUCTURE, checklist);
      }
    },

    checklistPreview() {
      var checklist = JSON.parse(
        JSON.stringify(this.$store.getters.currentCheckList)
      );
      checklist.section_start.questions = checklist.section_start.questions.slice(
        1
      );
      return checklist;
    },

    process: {
      get: function() {
        var processNew = this.$store.getters.currentProcess;
        processNew.processDateStart = this.today;
        return processNew;
      },
      set: function(process) {
        this.$store.commit(SET_PROCESS, process);
      }
    },

    selectAllLocations: {
      get: function() {
        return this.locations
          ? this.process.selectedLocations.length == this.locations.length
          : false;
      },
      set: function(value) {
        var selectedLocations = [];

        if (value) {
          this.locations.forEach(function(location) {
            selectedLocations.push(location.id);
          });
        }

        this.process.selectedLocations = selectedLocations;
      }
    },
    selectAllRoles: {
      get: function() {
        return this.roles
          ? this.process.selectedRoles.length == this.roles.length
          : false;
      },
      set: function(value) {
        var selectedRoles = [];

        if (value) {
          this.roles.forEach(function(role) {
            selectedRoles.push(role.id);
          });
        }

        this.process.selectedRoles = selectedRoles;
      }
    },

    selectAllMailgroups: {
      get: function() {
        return this.mailgroups
          ? this.process.selectedMailgroups.length == this.mailgroups.length
          : false;
      },
      set: function(value) {
        var selectedMailgroups = [];

        if (value) {
          this.mailgroups.forEach(function(role) {
            selectedMailgroups.push(role.id);
          });
        }

        this.process.selectedMailgroups = selectedMailgroups;
      }
    }
  },

  created() {
    //console.log(this.process);
    // purge process state to start from scratch
    // console.log(this.checklistStructure);
    this.$store.commit(PURGE_PROCESS_STATE);
    // console.log(this.checklistStructure);
    this.loadAims();
    this.loadLocations();
    this.loadRoles();
    this.loadMailgroups();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Создание нового", route: "new" },
      { title: "Тесты" }
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v3", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    // Validation before going to next page
    // wizard.on("beforeNext", function(wizardObj) {
    // validate the form and use below function to stop the wizard's step
    // console.log("validating" + wizardObj.currentStep);
    //wizardObj.stop();
    // });

    // Change event
    var vm = this;
    wizard.on("change", function(wizardObj) {
      //  console.log("changing from " + wizardObj.currentStep);
      //  console.log("to " + wizardObj.newStep);

      if (wizardObj.currentStep === 1) {
        //1st step validation
        if (
          vm.process.processType === "" ||
          vm.process.processName === "" ||
          vm.process.processAim === ""
        ) {
          Swal.fire({
            title: "",
            text: "Заполните обязательные поля: Название, Тип теста!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
          wizardObj.stop();
        }
      } else if (wizardObj.currentStep === 2) {
        //2nd step validation
        if (
          vm.process.processDateStart === null ||
          vm.process.processTime === 0 ||
          ((vm.process.processTime === 2 || vm.process.processTime === 3) &&
            vm.process.processDateFinish === null) ||
          (vm.process.processTime === 3 && vm.process.processPeriod === null) ||
          (!vm.process.hours &&
            (vm.process.timeStart === null || vm.process.timeFinish === null))
        ) {
          Swal.fire({
            title: "",
            text:
              "Заполните обязательные поля: Временные критерии, часы действия!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
          wizardObj.stop();
        }
      } else if (wizardObj.currentStep === 3) {
        //3rd step validation
        /*  console.log(
          vm.findObjectByKeyVal(vm.checklistStructure.sections, "text", "")
        );*/
        if (
          vm.findObjectByKeyVal(vm.checklistStructure.sections, "text", "") ||
          vm.findObjectByKeyVal(vm.checklistStructure.sections, "type", 0)
        ) {
          Swal.fire({
            title: "",
            text: "Заполните обязательные поля в тесте!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
          wizardObj.stop();
        }
      }

      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },

  methods: {
    findDescription() {
      //    console.log(this.processAims);
      //    console.log(this.process.processAim.id);
      var ret = this.processAims.find(
        el => el.id === this.process.processAim.id
      );
      // console.log(ret);
      if (typeof ret !== "undefined" && ret.hasOwnProperty("description")) {
        return ret.description;
      } else {
        return "";
      }
    },

    async loadAims() {
      try {
        return new Promise(resolve => {
          ApiService.get("api/v1/conditiontype").then(({ data }) => {
            this.processAims = data.results;
            resolve(data);
          });
        });
      } catch ({ response }) {
        console.error(response);
      }
    },

    async loadLocations() {
      try {
        return new Promise(resolve => {
          ApiService.get("api/v1/location").then(({ data }) => {
            this.locations = data.results;
            resolve(data);
          });
        });
      } catch ({ response }) {
        console.error(response);
      }
    },

    async loadRoles() {
      try {
        return new Promise(resolve => {
          ApiService.get("api/v1/groups").then(({ data }) => {
            this.roles = data.results;
            resolve(data);
          });
        });
      } catch ({ response }) {
        console.error(response);
      }
    },

    async loadMailgroups() {
      try {
        return new Promise(resolve => {
          ApiService.post("api/v1/findCustomData/", {
            user_id: this.$store.getters.currentUser.user.id,
            type: "mailer"
          }).then(({ data }) => {
            this.mailgroups = data;
            resolve(data);
          });
        });
      } catch ({ response }) {
        console.error(response);
      }
    },

    addSection(order) {
      this.checklistStructure.sections.push({
        name: "",
        order: order,
        questions: [
          {
            type: 0,
            text: "",
            value: "",
            file: "",
            answers: [
              {
                text: "",
                placeholder: "Текст ответа",
                subs: true,
                deletable: true,
                questions: [],
                readonly: false,
                order: 1
              }
            ],
            order: 1,
            required: true
          }
        ]
      });
    },

    copyPasteSection(section, order) {
      this.checklistStructure.sections.push({
        name: section.name,
        order: order,
        questions: section.questions
      });
    },

    deleteSection(section) {
      this.checklistStructure.sections = this.checklistStructure.sections.filter(
        function(obj) {
          return JSON.stringify(obj) !== JSON.stringify(section);
        }
      );
      var counter = 1;
      this.checklistStructure.sections = this.checklistStructure.sections.map(
        section => ({ ...section, order: counter++ })
      );
    },

    addQuestion(section, order) {
      section.questions.push({
        type: 0,
        text: "",
        value: "",
        file: "",
        answers: [
          {
            text: "",
            placeholder: "Текст ответа",
            subs: true,
            deletable: true,
            questions: [],
            readonly: false,
            order: 1
          }
        ],
        order: order,
        required: true
      });
    },

    submit(e) {
      e.preventDefault();
      this.$store
        .dispatch(SEND_PROCESS)
        .then(() => {
          Swal.fire({
            title: "",
            text: "Тест успешно сохранен!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          }).then(() => {
            this.$router.push("/process/all");
          });
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Произошла ошибка, повторите попытку!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
        });
    },

    gotoAll() {
      this.$store.commit(PURGE_PROCESS_STATE);
      this.$router.push("/process/all");
    },

    findObjectByKeyVal: function(obj, key, val) {
      // console.log(obj);
      if (!obj || typeof obj === "string") {
        return null;
      }
      if (obj[key] === val) {
        return obj;
      }

      for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
          var found = this.findObjectByKeyVal(obj[i], key, val);
          if (found) {
            return found;
          }
        }
      }
      return null;
    }
  }
};
</script>
